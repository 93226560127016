import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import "./dropdown.sass";

const DropDown = props => {
  const [dropdownActive, setDropdownActive] = useState(false)
  return (
    <div>
      <div className={`dropdown is-accordion ${dropdownActive ? `is-active` : ""}`}>
        <div
          className={`dropdown-trigger`}
          onClick={() => setDropdownActive(!dropdownActive)}
        >
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu2"
          >
            <span>{props.question}</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className={`dropdown-menu`} id="dropdown-menu2" role="menu">
          <div className={`dropdown-content`}>
            {props.children}
            {(props.link || props.contact ? <hr className="dropdown-divider"/> : '')}
            <p className="contact-wrapper">
              {(props.link ? 
              <a
              className={`button is-rounded is-link is-outlined`}
              href={props.link}
              target="_blank"
              rel="noreferrer"
              >
                {props.link}
              <FontAwesomeIcon icon={faArrowCircleRight} />
              </a> : ''
              )}
              {(props.contact ? <span className={`contact button is-outline is-rounded`}>{props.contact}</span> : '')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropDown
