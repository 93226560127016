import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import DropDown from "../components/Dropdown";
import pdf from "../assets/mandatory-reporting-laws.pdf";
import pdf2 from "../assets/Our Attitude Toward Seeking Professional Psychological Help.pdf";
import pdf3 from "../assets/Handling Allegations of Abuse.pdf";

// eslint-disable-next-line
export const FAQPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <p>
                And the peace of God, which surpasses every man’s understanding,
                will guard your hearts and your thoughts in Christ Jesus.
              </p>
              <p class="text-end">Philippians 4:7</p>

              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <DropDown
                question="What is the proper attitude of a Christian toward seeking psychological help?"
                link=""
              >
                <>
                  <p>
                    A statement on the attitude of Christians toward seeking
                    professional psychological help can be found here:
                  </p>
                  <hr className="dropdown-divider"></hr>
                  <p>
                    <a href={pdf2} target="_blank" rel="noreferrer">
                      Our Attitude Toward Seeking Professional Psychological
                      Help
                    </a>
                  </p>
                </>
              </DropDown>
              <DropDown question="What causes mental illness?" link="">
                <>
                  <p>
                    Because man is tripartite and has a body, soul, and spirit,
                    there is in a sense a tripartite contribution to mental
                    illness, and all three parts need care. In the spiritual
                    realm, we have a ruthless enemy which seeks to attack and
                    damage our soul so that we cannot participate in God’s plan
                    for us, and there is an influence of sin which leads to a
                    contamination of our body’s need for nourishment,
                    procreation, and protection. Our enjoyment and experience of
                    Christ, prayer to the Lord, and meeting with other believers
                    are a vital part of addressing these matters. In the
                    physical realm, a lack of proper care of our sleep, diet,
                    and exercise can contribute to mental illness, as can
                    biochemical or physiological differences which may
                    necessitate the help of a psychiatrist. Finally, in the
                    psychological realm, we can develop unhealthy thoughts,
                    emotions, and behaviors as a result of our experiences, such
                    as loss, trauma, abandonment, or abuse, which may
                    necessitate the help of a psychologist or
                    therapist/counselor.
                  </p>
                </>
              </DropDown>
              <DropDown
                question="How do I know if I need professional help?
      "
                link=""
              >
                <>
                  <p>
                    Everyone experiences emotions such as depression and anxiety
                    as part of normal human experience. However, a sign that
                    professional help may be needed is if these emotions are
                    causing significant distress. For example, if you are
                    feeling so depressed that you are crying often, not doing
                    things you usually enjoy, or feeling worthless and hopeless,
                    or if you are feeling so anxious that you are often worrying
                    and even your body feels anxious, you may need professional
                    help from a mental health professional. Another sign that
                    professional help may be needed is if these emotions are
                    causing functional impairment. For example, if you are
                    feeling so depressed or anxious that you are unable to go to
                    school or work, participate in social activities, or get out
                    of bed, or these types of activities require significant
                    effort, you may need professional help from a mental health
                    professional.
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Who are considered mental health professionals?"
                link="https://www.nami.org/About-Mental-Illness/Treatments/Types-of-Mental-Health-Professionals"
              >
                <>
                  <p>
                    Mental health professionals are individuals who have
                    specialized training in diagnosing and/or treating mental
                    health disorders. The two main types of interventions used
                    include medications and therapy/counseling. There is a wide
                    range of training requirements for different types of mental
                    health professionals. In the United States (and many
                    countries), mental health professionals have advanced
                    degrees and a license and/or certification in their fields.
                    For an introduction to the different types of mental health
                    providers, visit:
                  </p>
                </>
              </DropDown>
              <DropDown
                question="What is the difference between a psychiatrist and a psychologist?"
                link=""
              >
                <>
                  <p>
                    Psychiatrists and psychologists are two types of mental
                    health professionals. A psychiatrist attends medical school,
                    obtains a M.D. or D.O. with specialty training in
                    psychiatry, and is trained under a medical perspective of
                    mental illness (i.e., focus on physiological and biochemical
                    causes). A psychologist attends graduate school, obtains a
                    Ph.D. or Psy.D. in clinical or counseling psychology, and is
                    trained under a biopsychosocial perspective of mental
                    illness (i.e., focus on the interconnection between biology,
                    psychology, and environment). Regarding assessment, or the
                    diagnosis of mental health disorders, psychiatrists
                    primarily use clinical interviewing and screening measures,
                    while psychologists typically use a longer battery of
                    assessment measures. Regarding intervention, or the
                    treatment of mental health disorders, psychiatrists
                    primarily use medication, which are effective for symptom
                    reduction and often work quickly, while psychologists
                    primarily use therapy or counseling (e.g., cognitive
                    behavioral therapy, dialectical behavior therapy, acceptance
                    and commitment therapy), which are effective for addressing
                    negative thoughts, emotions, or behaviors over a longer
                    period of time. The most effective treatment is usually a
                    combination of medication and therapy. Oftentimes, it will
                    take multiple trials of medications or therapists to find
                    the best fit, and it is important to monitor the
                    effectiveness of both medication and therapy so that
                    adjustments can be made for the best treatment.
                  </p>
                </>
              </DropDown>
              <DropDown question="How should I find professional help?" link="">
                <>
                  <p>
                    Finding professional help for mental health concerns can be
                    similar to finding help for other health concerns. If you
                    have a primary care physician, you may choose to bring up
                    your concerns to get some local recommendations. If you have
                    insurance, you may contact your insurance provider to find
                    in-network mental health professionals. If you are working,
                    check with your human resources or employee assistance
                    program to learn about mental health resources available to
                    you. If you are a student, check with your school or
                    university, as most offer free or reduced mental health
                    services. Many mental health clinics, including training
                    clinics (e.g., universities), offer services on a “sliding
                    scale” for students or those in financial need. If these
                    resources are not available to you, you may search for local
                    mental health providers online using various search engines,
                    such as those provided in the General Resources section of
                    this website. Notably, it can be difficult to find the best
                    provider for you, and it is important to not be discouraged
                    if you have a negative experience with treatment. Many times
                    it may take multiple attempts to find the best fit with a
                    therapist or medication.
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Can I benefit from mental health professionals who are not Christians?"
                link=""
              >
                <>
                  <p>
                    When you are trying to find a health care professional in
                    any field, there are many factors to consider. Typically,
                    the most important factor is whether the professional is
                    trained (i.e., has the appropriate education and experience)
                    and qualified (i.e., has the appropriate certification or
                    specialty) to meet your need. Additional factors could
                    include distance, in-network vs. out-of-network status,
                    patient reviews, and personal characteristics of the
                    professional (e.g., gender, age, race/ethnicity, religious
                    beliefs). It is up to you to examine these additional
                    factors and determine which professional is the best fit for
                    you. It is worth highlighting that the more advanced and
                    specialized training the professional has in treating
                    concerns like yours, the more likely you are to see
                    significant improvements. Notably, mental health
                    professionals are trained to be respectful of patients’
                    beliefs and worldviews, especially religious beliefs. This
                    respect for your faith can allow you to make significant
                    progress in treatment even with a professional who does not
                    share the same religious beliefs.
                  </p>
                </>
              </DropDown>
              <DropDown
                question="How should I respond to someone who tells me about mental health issues or concerns of suicide?"
                link=""
              >
                <>
                  <p>
                    If someone opens up to you about mental health issues, the
                    most important step is to listen with empathy and without
                    judgment. Provide encouragement and hope, in addition to
                    information about self-help and professional help. If you
                    are concerned regarding the possibility of suicide or
                    self-harm, it is important to ask directly about these
                    concerns and assess for the severity of risk. These concerns
                    should be taken seriously, and confidentiality should not be
                    promised to ensure safety. Developing a safety plan or
                    contract with the individual and notifying a family member
                    are important for their safety. Follow-up is typically
                    necessary, including initiation of outpatient therapy,
                    intensive outpatient or residential programs, or inpatient
                    treatment. In high-risk or emergency situations, bring the
                    individual to the nearest emergency department or contact
                    emergency services (911) or the suicide hotline
                    (1-800-273-8255) and stay with the individual to ensure
                    their safety. If applicable, remove any practical access to
                    objects or factors in the immediate environment that could
                    be used to attempt suicide.
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Why do we encounter stigma regarding mental health?"
                link=""
              >
                <>
                  <p>
                    Unfortunately, there is often a stigma, or negative
                    perception, surrounding mental health issues or treatment
                    both in society and among Christians. For many, this stigma
                    often presents a barrier to seeking the appropriate
                    professional help. As Christians, we must consider our own
                    reactions to mental health issues and how they may
                    contribute to this stigma. Fear, misinformation, and
                    judgment are the main contributors to stigma towards mental
                    illness. Because of fear, we may avoid or pull away from
                    individuals that open up regarding mental health
                    difficulties, which may increase their feelings of
                    isolation. Because of misinformation, we may give unhelpful
                    responses such as telling them they need to pray more or
                    touch their spirit more, which may discourage them from
                    finding appropriate professional help. And because of
                    judgment, we may respond with criticism or invalidate their
                    experience, which may exacerbate their suffering. To
                    decrease this stigma, we must not be afraid to talk about
                    mental health, be adequately informed about appropriate
                    assessment and intervention, and be careful regarding having
                    an attitude that may devalue the suffering of others.
                  </p>
                </>
              </DropDown>
              <DropDown
                question="How should we handle allegations of abuse?"
                link=""
              >
                <>
                  <p>
                    A statement on how allegations of abuse should be handled
                    can be found here:
                  </p>
                  <hr className="dropdown-divider"></hr>
                  <p>
                    <a href={pdf3} target="_blank" rel="noreferrer">
                      Handling Allegations of Abuse
                    </a>
                  </p>
                  <hr className="dropdown-divider"></hr>
                  <p>
                    Information on laws by state regarding reporting child abuse
                    and neglect can be found here:
                  </p>
                  <hr className="dropdown-divider"></hr>
                  <p>
                    <a href={pdf} target="_blank" rel="noreferrer">
                      Mandatory Reporting Laws
                    </a>
                  </p>
                </>
              </DropDown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FAQPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const FAQPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FAQPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

FAQPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FAQPage;

export const FaqPageQuery = graphql`
  query FAQPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
